import { Navigate, useNavigate, useParams } from "react-router-dom";

import { PressReleaseData } from "../components/Article";
import BigSideSection from "../components/BigSideSection";
import Page from "../components/Page";
import Tag from "../components/Tag";
import TopSection from "../components/TopSection";
import { useMediaQuery } from "../hooks/useMediaQuery";
import { sectionPadding } from "../utils";

import { ReactComponent as Arrow } from "../icons/arrow.svg";
import background from "../images/top-section-bg.webp";

const PressRelease = () => {
  const [isDesktop, hasIsDesktopChanged] = useMediaQuery("(min-width: 768px)"); // md
  const navigate = useNavigate();
  const shouldAnimationHappen = isDesktop && !hasIsDesktopChanged;
  const contactAddress = "contact@scientalab.com";
  const slug = useParams().slug;
  const pressRelease = pressReleases.find(
    (pressRelease) => pressRelease.slug === slug
  );
  if (!pressRelease) return <Navigate to="/404" />;
  const { title, date, tag, color, content } = pressRelease;
  const tagAnimationClasses = `${
    shouldAnimationHappen &&
    "md:animate-[appearFromRight_1s_linear_1_normal_forwards] md:opacity-0"
  }`;
  return (
    <Page>
      <div className="-mt-20"></div>
      <TopSection title="Press Release" />
      <div className={`${sectionPadding}`}>
        <div className="flex justify-center space-y-8">
          <article className="prose lg:prose-lg">
            <div className="grid-stack grid">
              <button
                onClick={() => navigate(-1)}
                className="mb-8 inline-flex h-fit flex-row items-center text-base underline"
              >
                <Arrow className="mr-2 inline w-3 rotate-180 " />
                Back to Newsroom
              </button>
              <div className="hidden w-1 -translate-x-8 animate-[expand_1s_ease-in-out_1] rounded-sm bg-gradient-to-b from-primary via-secondary to-tertiary md:block" />
              <h2 className="text-primary">{title}</h2>
            </div>
            <span className="inline-block space-x-2">
              <Tag
                content={date}
                color={color}
                tagTextColor="text-white"
                className={tagAnimationClasses}
              />
              {tag && (
                <Tag
                  content={tag}
                  color={color}
                  tagTextColor="text-white"
                  className={`md:[animation-delay:50ms] ${tagAnimationClasses}`}
                />
              )}
            </span>
            {content}
          </article>
        </div>
      </div>
      <BigSideSection backgroundImgUrl={background} fullContent>
        <h3 className="mb-8 text-4xl font-bold">Get in touch</h3>
        <p>
          For media inquiries, please reach out to{" "}
          <a
            className="underline underline-offset-4"
            href={`mailto:${contactAddress}`}
          >
            {contactAddress}
          </a>
        </p>
      </BigSideSection>
      <div className="sm:mb-20"></div>
    </Page>
  );
};

export const pressReleases: PressReleaseData[] = [
  {
    title:
      "Towards the identification of Molecular Biomarkers for Sjögren's Disease Stratification using the foundation model EVA: latest research results presented by Scienta Lab at the 2024 ACR Convergence Conference.",
    slug: "acr-sjogren-disease-stratification",
    date: "14/11/2024",
    color: "tertiary",
    tag: "EN",
    tagTextColor: "text-[#0e1f21]",
    content: (
      <div>
        <p className="lead">#artificialintelligence #sjogren</p>
        <p>
        Scienta Lab, a pioneering company in AI-powered precision immunology, today announced that the results of its research collaboration on the identification of molecular biomarkers for Sjögren's Disease stratification with Servier, an independent international pharmaceutical group, have been selected for an oral presentation at the 2024 American College of Rheumatology (ACR) Convergence Congress in Washington D.C., USA. 
        </p>
        <p>
        Sjögren’s Disease, a rare chronic autoimmune disease known for its diverse clinical manifestations and complex underlying biology, has long presented challenges to drug developers. To date, no targeted therapy has been approved, leading to a large medical unmet need for affected patients. The disease heterogeneity is particularly complex to tackle in clinical development, resulting in most trials failing to demonstrate efficacy. 
        </p>
        <p>
        Scienta Lab and Servier collaborated to implement a bold and innovative paradigm change to Sjögren’s drug development landscape: precision medicine. The collaboration aimed at tackling disease heterogeneity by identifying disease subtypes with homogeneous clinical and biological features. This approach supports the implementation of specific inclusion criteria for clinical trials, reducing the targeted population but improving the probabilities of success, and ensuring more targeted drugs for patients down the line. 
        </p>
        <p>
        In this context, the group reanalyzed large-scale multimodal research cohorts with EVA, Scienta Lab’s proprietary foundation model dedicated to immune-mediated and inflammatory diseases.  The objective was to identify new transcriptomic biomarkers predictive of disease activity, specifically on hematological and biological aspects of Sjögren’s Disease. The group achieved the following key findings:
        <ul>
          <li>
          SERPING1 expression is correlated with both biological and hematological values,  
          </li>
          <li>
          CD40LG expression stands out as a key modulator of hematological activity,
          </li>
          <li>
          Genes involved in the interferon pathway, including IFI44L, IFI27, SIGLEC1, and CMPK2 are present in both domains, emphasizing the role of interferon signaling in disease physiopathology.
          </li>
        </ul>
        </p>
        <p>
        The collaboration will now be extended to the identification of biomarkers predictive of systemic involvements of the disease in order to complete its stratification.
        </p>
        <p>
        This project was conducted within the IMI funded NECESSITY consortium, which brings together a group of academics, industry and patient organizations dedicated to better understanding Sjögren's Disease. Led by Pr. Xavier Mariette (Department of Rheumatology, University Hospital Bicêtre APHP – Paris), the NECESSITY Consortium aims at providing tailored outcome measures for clinical trials in Sjögren’s Disease through the identification and validation of new clinical endpoints and biomarkers. 
        </p>
        <blockquote>
          <p className="!mb-1">
          In many autoimmune diseases, targeted therapies are facing a glass ceiling resulting in insufficient efficacy for a significant proportion of patients. This challenge is mainly due to the clinical and biological heterogeneity of these diseases. At Scienta Lab, we believe that in a few years from now, these diseases will be divided into new clinical and biological entities, enabling pharmaceutical companies to develop more appropriate therapies. 
Scienta Lab’s scientific ambition is to be at the forefront of the stratification of immuno-inflammatory disease. Our goal is to identify complex multimodal signatures, combining systemic and local involvements, to stratify these diseases into homogeneous subtypes. Scienta Lab’s method combines an AI foundation model with a state-of-the-art XAI technology, specifically developed to extract biomedical knowledge from scarce datasets.
          </p>
          <b>- Vincent Bouget, co-founder & CSO</b>
        </blockquote>
        <blockquote>
          <p className="!mb-1">
          At Servier, we are pleased to collaborate with Scienta Lab and the NECESSITY European consortium to use model-informed strategies for better managing Sjögren's disease. Identifying biomarkers through transcriptional signatures that predict disease activity could help classify Sjögren's patients into different molecular groups, enhancing treatment options.
          </p>
          <b>Dr. Philippe Moingeon, Institut de Recherches Internationales Servier, Departments of Translational Medicine and Immuno-Inflammatory Diseases Research and Development</b>
        </blockquote>
        <p>
        As disease stratification is the cornerstone of personalized medicine, Scienta Lab intends to strengthen these transcriptomic profiles with biological information and phenotypic data to support a better understanding of the disease heterogeneity and ultimately ensure the best clinical outcomes for patients with Sjogren’s Disease.
        </p>        
        <p className="lead">
        Identification of Molecular Biomarkers for Sjögren's Disease Stratification via a Deep Learning Foundation Model Dedicated to Immune-Mediated and Inflammatory Diseases
        </p>
        <b>Oral presentation time & Place</b>
        <ul className="!mt-0">
          <li>-	Monday, November 18, 3:00 PM - 4:30 PM, Sjögren's Syndrome – Basic & Clinical Science session</li>
          <li>
          Walter E. Washington Convention Center – Room 103AB, Washington, DC, USA
          </li>
        </ul>
      </div>
    ),
  },
  {
    title:
      "Scienta Lab to present its Transcriptomic Foundation Model dedicated to the identification and validation of New Therapeutic Targets through a use case in Inflammatory Bowel Diseases at the 2024 ACG Annual Scientific Meeting",
    slug: "acg-transcriptomic-target-discovery",
    date: "28/10/2024",
    color: "tertiary",
    tag: "EN",
    tagTextColor: "text-[#0e1f21]",
    content: (
      <div>
        <p className="lead">#artificialintelligence #ibds</p>
        <p>
        Scienta Lab, a pioneering company in AI-powered precision immunology, 
        is pleased to announce that the results of its research leveraging its 
        Transcriptomic Foundation Model EVA for therapeutic target identification 
        in inflammatory bowel disease (IBD) will be presented at the upcoming 2024 
        American College of Gastroenterology (ACG) Annual Scientific Meeting in Philadelphia, PA. 
        The study has been honored with the prestigious Presidential Poster Award, 
        a recognition granted by the Abstract Selection Committee to approximately 5% 
        of accepted abstracts annually for their high quality, novel, unique, 
        or interesting research.
        </p>
        <p>
        Because of the complex and heterogeneous nature of IBD, patients continue to 
        face challenges linked with inconsistent responses to available therapies, 
        despite advances in pharmaceutical options. IBD encompasses a range of conditions, 
        including Crohn's disease and ulcerative colitis, which are influenced by diverse 
        genetic, immunological, microbial, and environmental factors, contributing to inconsistent 
        therapeutic outcomes. As a result, identifying the most effective treatment for each patient 
        often requires a personalized, trial-and-error approach.
        </p>
        <p>
        To address this therapeutic ceiling, Scienta Lab leveraged EVA, its 
        state-of-the-art transcriptomic foundation model, in a comprehensive study 
        to identify new drug targets for IBD. EVA was used to construct two distinct 
        gene regulatory networks, representing healthy individuals and patients with IBD. 
        A comparison of these two networks supported the identification of 20 genes with 
        significant differential interactions, representing promising new therapeutic 
        targets. Among these, 10 genes showed strong links to existing drug targets, 
        highlighting the potential of this methodology to identify impactful therapeutic 
        targets.
        </p>
        <p>
        The research also revealed that the identified genes are not only differentially 
        regulated between IBD patients and healthy controls but also exhibit notable 
        changes in expression levels. Key differences were observed in pathways associated 
        with immune responses, such as interleukin signaling and neutrophil activity, which 
        are central to IBD pathophysiology.
        </p>
        <blockquote>
          <p className="!mb-1">
          By harnessing the power of our technology to map out complex gene networks, 
          we have identified novel molecular targets that could overcome the current 
          limitations in IBD treatments. We are proud to present our findings at ACG 2024,
           setting the stage for more personalized, targeted therapies, with the goal of 
           transforming patient care.
          </p>
          <b>- Vincent Bouget, CSO</b>
        </blockquote>
        <p>
        The next phase of this research will focus on evaluating these targets for safety 
        and druggability to advance them into potential therapeutic options.
        </p>        
        <p className="lead">
        Identification of New Therapeutic Targets in IBD Using a Transcriptomic Foundation Model
        </p>
        <b>Poster time & Place</b>
        <ul className="!mt-0">
          <li>Tuesday, October 29th, 10:30 AM – 4:00 PM</li>
          <li>
            Pennsylvania Convention Center, Philadelphia, PA, USA
          </li>
        </ul>
      </div>
    ),
  },
  {
    title:
      "Scienta Lab Unveils Cutting-edge AI Model Enhancing Primary Sjögren's Syndrome Diagnosis at EULAR 2023",
    slug: "eular-sjogrens-syndrome-diagnosis",
    date: "26/05/2023",
    color: "tertiary",
    tag: "EN",
    tagTextColor: "text-[#0e1f21]",
    content: (
      <div>
        <p className="lead">#artificialintelligence #primarysjogrensyndrome</p>
        <p>
          Scienta Lab, is a pioneering deeptech company modeling immune-mediated
          inflammatory diseases through an explicable machine learning platform.
          Scienta Lab's research collaboration with Pr. Mariette and Dr. Bitoun
          (Bicêtre Rheumatology Department, AP-HP - Paris, France) on the
          diagnosis of primary Sjögren's syndrome will be presented at the 2023
          European Union League Against Rheumatism (EULAR) Annual Congress in
          Milan, Italy. This project was conducted within the IMI funded
          NECESSITY consortium, gathering a group of academics, industry and
          patient organizations dedicated to better understanding Sjögren's
          syndrome.
        </p>
        <p>
          Primary Sjögren syndrome (pSS) is a rare chronic autoimmune disease
          that primarily affects glands producing tears and saliva, causing
          symptoms such as intense fatigue, chronic pain, dry eyes and mouth.
          The syndrome can also affect other parts of the body, including
          joints, skin, and vital organs such as the lungs and kidneys; and is
          known to be linked with serious co- morbidities such as cancer (more
          than 10% of patients with primary Sjögren's Syndrome will develop
          lymphoma).
        </p>
        <p>
          Accurately diagnosing pSS remains challenging, as it relies on a
          combination of multiple criteria such as the analysis of biopsies,
          assessment of autoantibody status, and evaluation of ocular and oral
          dryness levels. The analysis of salivary gland biopsies aims at
          measuring the extent of lymphocyte infiltration, which is expressed by
          pathologists in a score known as the focus score. A focus score equal
          to or greater than 1 serves as a definitive histological criterion for
          confirming pSS diagnosis; therefore, its accurate evaluation plays a
          pivotal role in pSS diagnosis. However, this evaluation requires
          specialized expertise, making it difficult for non- specialized
          medical centers to achieve consistent results - studies have shown a
          reclassification rate of up to 53% when expert rescoring is performed.
        </p>
        <p>
          In this context, Scienta Lab developed an AI model capable of
          predicting the focus score and diagnosis of pSS with high accuracy
          using only digital scans of biopsy slides.
        </p>
        <blockquote>
          <p className="!mb-1">
            With our expertise in Immunology & inflammation coupled with machine
            learning capabilities, we were able to develop an automatized and
            normalized grading of lymphocyte aggregates via machine learning to
            considerably enhance diagnosis reliability. The resulting technology
            has not only the potential to transform clinical care, but also drug
            development by supporting the identification of histological
            biomarkers associated with pSS diagnosis. I'd like to thank all
            NECESSITY participating members for their support along this
            collaborative research project.
          </p>
          <b>- Vincent Bouget, CSO</b>
        </blockquote>
        <p>
          Further clinical evaluation in real-world clinical practice along with
          thorough validation amongst a broader group of individuals will be
          needed before routine use in clinical and research activities. Scienta
          Lab is actively engaged in progressing towards these next steps, and
          ultimately aims at identifying new biomarkers linked to pSS in
          salivary gland biopsies to improve patient therapeutic management.
        </p>
        <blockquote>
          <p className="!mb-1">
            The algorithm we developed only uses real world data that is already
            gathered during clinical practice. This allowed the team to get rid
            of time consuming, expensive, and hard to manage human labeling
            pipelines that have been the standard so far in deep learning
            assisted pathology. This means that we can easily scale to orders of
            magnitude of additional data in the future to solidify our current
            results, more accurately help pathologists and make new discoveries
            concerning histological biomarkers. This was made possible because
            we leveraged semi-supervised learning and self-supervised learning
            concurrently. Definitely a first in AI assisted pathology for
            Sjögren.
          </p>
          <b>
            - Louis Basseto, Research Scientist, in charge of the technical
            development
          </b>
        </blockquote>
        <p className="lead">
          Deep Learning Accurately Predicts Focus Score and Diagnosis of Primary
          Sjögren's Syndrome Using Labial Salivary Gland Biopsies
        </p>
        <b>Presentation time & Place</b>
        <ul className="!mt-0">
          <li>Friday, June 2, 2023, 11:35 AM CEST</li>
          <li>
            MiCo Convention Center, South Hall, Session room 1 (Milano, Italy)
          </li>
        </ul>
      </div>
    ),
  }
];

export default PressRelease;
