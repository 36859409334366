import BigSideSection from "../components/BigSideSection";
import DoubleColsHalfFilledSection from "../components/DoubleColsHalfFilledSection";
import Page from "../components/Page";
import { Quote } from "../components/PartnerQuote";
import PartnerQuoteSlider from "../components/PartnerQuoteSlider";
import { Portrait } from "../components/TeamMemberPortrait";
import { TeamMemberPortraits } from "../components/TeamMemberPortraits";
import TopSection from "../components/TopSection";
import { pictogramHeight, sectionPadding } from "../utils";

import { ReactComponent as JoinUs } from "../icons/joinUs.svg";
import { ReactComponent as Support } from "../icons/support.svg";
import { ReactComponent as Team } from "../icons/team.svg";
import { ReactComponent as WordPartners } from "../icons/wordPartners.svg";
import _21st from "../images/21st.webp";
import ENS from "../images/ENS.webp";
import biolabs from "../images/biolabs-logo.webp";
import bpifrance from "../images/bpifrance.webp";
import centraleSupelec from "../images/centraleSupelec.webp";
import enterpriseEuropeNetwork from "../images/enterprise-europe-network-logo.webp";
import fondation_supelec from "../images/fondation_supelec.webp";
import future40 from "../images/future40.png";
import medicen from "../images/medicen.webp";
import netva from "../images/netva.webp";
import charlottePhotoUrl from "../images/charlotte_claye_square.webp";
import camillePhotoUrl from "../images/profile_camille_square.webp";
import julienPhotoUrl from "../images/profile_julien_square.webp";
import apollinePhotoUrl from "../images/profile_apolline_square.jpeg";
import yannisPhotoUrl from "../images/profile_yannis_square.jpeg";
import azizPhotoUrl from "../images/profile_aziz_square.jpeg";
import pierrePhotoUrl from "../images/profile_pierre_square.jpeg";
import vincentPhotoUrl from "../images/profile_vincent_square.webp";
import thomasPhotoUrl from "../images/thomas_hugle.webp";
import background from "../images/top-section-bg.webp";
import xavierPhotoUrl from "../images/xavier_mariette.webp";
import dennisPhotoUrl from "../images/profile_dennis_mcgonagle.png";

const portraits: Portrait[] = [
  {
    photoUrl: camillePhotoUrl,
    name: "Camille Bouget, PharmD",
    position: "Chief Executive Officer & Co-Founder",
    content:
      "PharmD | ex-Sanofi",
    linkedinUrl: "https://www.linkedin.com/in/camille-bouget",
  },
  {
    photoUrl: vincentPhotoUrl,
    name: "Vincent Bouget",
    position: "Chief Scientific Officer & Co-Founder",
    content:
      "Centrale Paris, IFSBM",
    linkedinUrl: "https://www.linkedin.com/in/vincent-bouget-053801151",
  },
  {
    photoUrl: julienPhotoUrl,
    name: "Julien Duquesne",
    position: "Chief Technology Officer & Co-Founder",
    content:
      "Centrale Paris, MVA",
    linkedinUrl: "https://www.linkedin.com/in/julien-duquesne",
  }, 
  {
    photoUrl: charlottePhotoUrl,
    name: "Charlotte Claye",
    position: "AI Research Scientist - PhD Student",
    content:
      "Centrale Paris, IFSBM",
    linkedinUrl: "https://www.linkedin.com/in/charlotte-claye/"
  },  
  {
    photoUrl: apollinePhotoUrl,
    name: "Apolline Bruley, PhD",
    position: "Computational Biologist",
    content:
      "Sorbonne University",
    linkedinUrl: "https://www.linkedin.com/in/apolline-bruley-5324a6192/"
  },
  {
    photoUrl: yannisPhotoUrl,
    name: "Yannis Cattan",
    position: "AI Research Scientist",
    content:
      "Mines ParisTech, MVA",
    linkedinUrl: "https://www.linkedin.com/in/yannis-cattan/"
  },
  {
    photoUrl: azizPhotoUrl,
    name: "Aziz Fouché, PhD",
    position: "Computational Biologist",
    content:
      "ENS Paris-Saclay, Institut Curie",
    linkedinUrl: "https://www.linkedin.com/in/aziz-fouche/"
  },
  {
    photoUrl: pierrePhotoUrl,
    name: "Pierre Marschall, PhD",
    position: "Scientific Project Manager",
    content:
      "University of Strasbourg",
    linkedinUrl: "https://www.linkedin.com/in/pierre-marschall"
  },
];

const partnersQuotes: Quote[] = [
  {
    photoUrl: xavierPhotoUrl,
    content:
      "The models developed by Scienta Lab help us to gain deep insights on auto-immune diseases. It paves the way to a better disease management and more favorable therapeutic outcomes for patients. »",
    name: "Pr. Xavier Mariette",
    position: "Head of Rheumatology Department",
    company: "CHU Bicêtre, Paris (France)",
  },
  {
    photoUrl: thomasPhotoUrl,
    content:
      "Scienta Lab team's dedication to drive precision medicine in Immunology & Inflammation is truly impressive. It's a pleasure to work alongside them, exchanging ideas and exploring innovative ways to improve patient outcomes. »",
    name: "Pr. Thomas Hügle",
    position: "Head of Rheumatology Department",
    company: "University Hospital, Lausanne (Switzerland)",
  },
  {
    photoUrl: dennisPhotoUrl,
    content:
      "Scienta Lab’s technology enables to advance the understanding of the underlying immune pathways causing immune-mediated and inflammatory diseases. »",
    name: "Pr. Dennis McGonagle",
    position: "Professor of Investigative Rheumatology",
    company: "Leeds Institute of Rheumatic and Musculoskeletal Medicine (UK)",
  },
];

const AboutUs = () => (
  <Page>
    <div className="row-start-2 -mt-20"></div>
    <TopSection title="ABOUT US" />
    <div className={`${sectionPadding}`}>
      <Team
        className={`col-span-full row-start-1 mb-5 fill-secondary ${pictogramHeight}`}
      />
      <div className="space-y-8">
        <h2 className="text-2xl font-bold text-primary">Meet the team</h2>
        <p className="font-bold">
          We are a multidisciplinary team of engineers, pharmacists, computer
          scientists and medical experts united around a bold vision: unleashing
          the power of machine learning to redefine precision immunology.
        </p>
        <TeamMemberPortraits portraits={portraits} />
      </div>
    </div>
    <div className="sm:mb-20"></div>
    <BigSideSection fullContent>
      <WordPartners className={`mb-5 fill-tertiary ${pictogramHeight}`} />
      <h2 className="mb-8 text-2xl font-bold">A word from our partners</h2>
      <PartnerQuoteSlider partnersQuotes={partnersQuotes} />
      <div className="sm:mb-36"></div>
    </BigSideSection>
    <div className="sm:-mb-20"></div>
    <DoubleColsHalfFilledSection
      backgroundImgUrl={background}
      leftChildren={theySupportUsSection}
      rightChildren={joinUsSection}
      filled="right"
      roundedCorners="tl"
    />
  </Page>
);

const theySupportUsSection = [
  <>
    <div className="sm:mt-10" />
    <Support
      className={`col-span-full row-start-1 mb-5 fill-secondary ${pictogramHeight}`}
    />
    <div className="space-y-8 ">
      <h2 className="mb-8 text-2xl font-bold text-primary">They support us</h2>
      <div className="flex flex-row flex-wrap gap-8 [&_img]:h-20 sm:[&_img]:h-24">
        <a
          href="https://21st.centralesupelec.com/"
          target="_blank"
          rel="noreferrer"
        >
          <img
            className="w-auto"
            src={_21st}
            alt="Logo of 21st by CentraleSupelec"
            width="100%"
            height="auto"
          />
        </a>
        <a href="https://ens-paris-saclay.fr/" target="_blank" rel="noreferrer">
          <img
            className="w-auto"
            src={ENS}
            alt="Logo of ENS Paris-Saclay"
            width="100%"
            height="auto"
          />
        </a>
        <a
          href="https://www.fondation-centralesupelec.fr/en/"
          target="_blank"
          rel="noreferrer"
        >
          <img
            className="w-auto"
            src={fondation_supelec}
            alt="Logo of Fondation CentraleSupélec"
            width="100%"
            height="auto"
          />
        </a>
        <a href="https://medicen.org/" target="_blank" rel="noreferrer">
          <img
            className="w-auto"
            src={medicen}
            alt="Logo of Medicen"
            width="100%"
            height="auto"
          />
        </a>
        <a
          href="https://www.centralesupelec.fr/"
          target="_blank"
          rel="noreferrer"
        >
          <img
            className="w-auto"
            src={centraleSupelec}
            alt="Logo of CentraleSupelec"
            width="100%"
            height="auto"
          />
        </a>
        <a
          href="https://www.biolabs.io/hotel-dieu"
          target="_blank"
          rel="noreferrer"
        >
          <img
            className="w-auto"
            src={biolabs}
            alt="Logo of Biolabs"
            width="100%"
            height="auto"
          />
        </a>
        <a href="https://een.ec.europa.eu/" target="_blank" rel="noreferrer">
          <img
            className="w-auto"
            src={enterpriseEuropeNetwork}
            alt="Logo of Enterprise Europe Newtork"
            width="100%"
            height="auto"
          />
        </a>
      </div>
      <h2 className="mb-8 text-2xl font-bold text-primary">
        Prizes and awards
      </h2>
      <div className="flex flex-row flex-wrap gap-8 [&_img]:h-20 sm:[&_img]:h-24">
        <div className="flex flex-col items-center">
          <a href="https://www.bpifrance.com/" target="_blank" rel="noreferrer">
            <figure>
              <img
                className="w-auto"
                src={bpifrance}
                alt="Logo of Bpifrance"
                width="100%"
                height="auto"
              />
              <figcaption className="mt-1 border-t border-gray-300 pt-2 text-left text-xs text-gray-400">
                Labeled Deeptech 2021
              </figcaption>
            </figure>
          </a>
        </div>
        <div className="flex flex-col items-center">
          <a
            href="https://netvafrance.com/en/home-1/"
            target="_blank"
            rel="noreferrer"
          >
            <figure>
              <img
                className="w-auto"
                src={netva}
                alt="Logo of NETVA"
                width="100%"
                height="auto"
              />
              <figcaption className="mt-1 border-t border-gray-300 pt-2 text-left text-xs text-gray-400">
                Award Winner 2022
              </figcaption>
            </figure>
          </a>
        </div>
        <div className="flex flex-col items-center">
          <a
            href="https://stationf.co/news/future40-2023"
            target="_blank"
            rel="noreferrer"
          >
            <figure>
              <img
                className="w-auto"
                src={future40}
                alt="Logo of Future 40"
                width="100%"
                height="auto"
              />
              <figcaption className="mt-1 border-t border-gray-300 pt-2 text-left text-xs text-gray-400">
                Award Winner 2023
              </figcaption>
            </figure>
          </a>
        </div>
      </div>
    </div>
  </>,
];

const joinUsSection = [
  <>
    <div className="sm:mt-10" />
    <JoinUs
      className={`col-span-full row-start-1 mb-5 fill-tertiary ${pictogramHeight}`}
    />
    <div className="space-y-8 text-white">
      <h2 className="text-2xl font-bold">Join us!</h2>
      <p>
        We are always on the look for brilliant minds who aspire to make a
        significant impact on the future of patient care in immunology.
      </p>
      <h3 className="text-xl font-bold">Interested in joining the team?</h3>
      <p className="text-xl">
        Drop us a line at:{" "}
        <a href="mailto:contact@scientalab.com" className="text-tertiary">
          contact@scientalab.com
        </a>
      </p>
      <p className="text-xl">We will get back to you shortly.</p>
    </div>
  </>,
];

export default AboutUs;
